<template>
  <v-combobox
    v-model="selectedEmails"
    class="forward-input"
    label="Prześlij do"
    :items="clientContactsWithEmail"
    :item-text="(item) => item.fullName + ' - ' + item.email"
    placeholder="Wybierz osoby, które mają otrzymać dokument"
    :rules="[emailsValidation, !required || rules.required]"
    outlined
    multiple
    dense
  >
    <template #selection="data">
      <v-chip
        :key="JSON.stringify(data.item)"
        :input-value="data.selected"
        :disabled="data.disabled"
        :text-color="getInputColor(data.item, '')"
        small
        v-bind="data.attrs"
      >
        <span>
          {{ data.item.email || data.item }}
        </span>
        <v-icon
          class="ml-2 mr-n1"
          small
          :color="getInputColor(data.item)"
          @click.stop="data.parent.selectItem(data.item)"
        >
          mdi-close-circle
        </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { mapState } from 'vuex'
import rules from '../../../utils/validators'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    rules
  }),
  computed: {
    ...mapState({
      clientContacts: state => state.client.entity?.contacts,
    }),
    clientContactsWithEmail() {
      return this.clientContacts?.filter(contact => !!contact.email) || []
    },
    selectedEmails: {
      // v-combobox does not support item-value prop
      get() {
        return [
          ...this.item.contactIds.map(id => this.clientContactsWithEmail.find(contact => id === contact.id)),
          ...this.item.externalContactEmails
        ]
      },
      set(value) {
        const contactIds = value.filter(contact => typeof contact === 'object').map(contact => contact.id)
        const externalContactEmails = value.filter(contact => typeof contact !== 'object')
        this.$emit('update', { contactIds, externalContactEmails })
      }
    }
  },
  methods: {
    getInputColor(item, successColor = 'primary', errorColor = 'error') {
      return rules.email(item.email || item) === 'Niepoprawny e-mail' ? errorColor : successColor
    },
    emailsValidation(values) {
      return values.every(
        contact => rules.email(contact.email || contact) !== 'Niepoprawny e-mail'
      ) || 'Wprowadzono niepoprawny email'
    },
  }
}
</script>

<style lang="scss" scoped>
.forward-input::v-deep  .v-input__slot {
  height: unset !important;
  .v-chip__content > span {
    text-overflow: ellipsis;
    overflow:hidden;
  }
}
</style>
